import React, { Fragment } from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'
import styles from './news-section.module.css'

import { addCloudinaryTransformPreset } from '../lib/util'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Sentry from "@sentry/react";

const NewsInlineLink =  ({ href, children }) => <a href={href} target="_blank">{children}</a>;
const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (<NewsInlineLink href={node.data.uri}>{children}</NewsInlineLink>)
  }
}

const newsItem = (entry) => (
  <Fragment>
    {entry.link &&
      <div>
        <a href={entry.link} target="_blank" className="hover-anim">
          {entry.image &&
            <img alt={entry.title}
              src={addCloudinaryTransformPreset(entry.image[0].secure_url, 'news_thumb')} 
              style={{ marginBottom: '0.5em' }} />
          }
          <strong className="news-title">{entry.title}</strong>
          
        </a>
        <div className={styles.newsBody}>
          {documentToReactComponents(entry.description.json, renderOptions)}
        </div>
      </div>
    }
    {!entry.link &&
      <div>
        {entry.image &&
          <img alt={entry.title}
            src={addCloudinaryTransformPreset(entry.image[0].secure_url, 'news_thumb')}
            style={{ marginBottom: '0.5em' }} />
        }
        <strong className="news-title">{entry.title}</strong>
        <div className={styles.newsBody}>
          {documentToReactComponents(entry.description.json, renderOptions)}
        </div>
      </div>
    }
    
    
  </Fragment>
)

// The image seems to get mistakenly shuffled from time to time. 
// https://github.com/gatsbyjs/gatsby/issues/20819

const generateNewsItemId = (item) => {
  if(item.node.startDate) {
    return item.node.title + ' - ' + item.node.startDate
  } else {
    return item.node.title
  }
}

export default class NewsSection extends React.Component {

  constructor(props) {
    super(props)
    const { news } = this.props 
    this.newsRef = {}
    news.forEach(n => {
      this.newsRef[generateNewsItemId(n)] = React.createRef()
    })
  }

  componentDidMount() {
    setInterval(() => {
      this.checkImageIntegrity()
    }, 1000)
  }

  checkImageIntegrity() {
    const { news } = this.props 

    news.forEach(n => {
      const node = this.newsRef[generateNewsItemId(n)].current
      if(node) {
        const imageElem = node.querySelector('img')
        if(imageElem && n.node.image.length > 0) {
          const targetImageUrl = addCloudinaryTransformPreset(n.node.image[0].secure_url, 'news_thumb')
          if(targetImageUrl != imageElem.src) {
            //TODO: send webhooks
            console.error('Found mismatched image url', imageElem.src, targetImageUrl)
            imageElem.src = targetImageUrl
            const msg = 'Found mismatched image url' + imageElem.src + '\nexpected: ' + targetImageUrl
            Sentry.captureMessage(msg, (err, eventId) => {
              // The message has now been sent to Sentry
            });
          }
        }
      }

    })    
  }

  render() {
    const { news } = this.props
    // const upcoming = newsList.find(n => n.node.title === 'Upcoming')
    // const onview = newsList.find(n => n.node.title === 'On View')
    let dateNow = (new Date()).toISOString()

    // For bug reproduction: If on serverside, use a fixed date in the past
    // if (typeof window === 'undefined') {
    //   dateNow = '2021-06-27T18:00:00.850Z'
    // }

    const upcomingNews = news.filter(n => (
      dateNow < n.node.startDate
    ))
    const onviewNews = news.filter(n => (
      (dateNow >= n.node.startDate) && (dateNow <= n.node.endDate)
    ))

    // console.log(news)  
    
    const archivedNews = news.filter(n => (
      dateNow > n.node.endDate
    )).sort((a, b) => {
      const bkey = b.node.endDate + '-'+ b.node.title
      const akey = a.node.endDate + '-'+ a.node.title
      return bkey.localeCompare(akey)
    })

    console.log('onview', onviewNews)
    console.log('upcoming', upcomingNews)
    console.log('archived', archivedNews)

    return (
      <div>
        {onviewNews.length > 0 &&
          <div>
            <h2>On View</h2>
            <ul className="article-list">
              {onviewNews.map(entry => (
                <div key={generateNewsItemId(entry)} 
                      className="news-onview" 
                      ref={this.newsRef[generateNewsItemId(entry)]}>
                  {newsItem(entry.node)}
                </div>
              ))}
            </ul>
          </div>
        }
        <br /><br />      
        {upcomingNews.length > 0 && 
          <div>
            <h2>Upcoming</h2>
            <ul className="article-list">
              {upcomingNews.map(entry => (
                <div key={generateNewsItemId(entry)} 
                      className="news-upcoming"
                      ref={this.newsRef[generateNewsItemId(entry)]}>
                  {newsItem(entry.node)}
                </div>
              ))}
            </ul>
          </div>
        }
        <br /><br />      
        {archivedNews.length > 0 && 
          <div>
            <h2>Past Exhibitions</h2>
            <ul className="article-list">
              {archivedNews.map(entry => (
                <div key={generateNewsItemId(entry)} 
                      className="news-archive"
                      ref={this.newsRef[generateNewsItemId(entry)]}>
                  {newsItem(entry.node)}
                </div>
              ))}
            </ul>
          </div>
        }
      </div>
    )
  }

}